import React, {useState} from "react"
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import TopBanner from "../components/TopBanner";
import * as portfolioPageStyles from "../styles/single-portfolio-page.module.scss"
import SimpleHomePageCarousel from "../components/SlickCarousel";
import { Container } from "../components/Layout/Container";
import GalleryModule from "../components/Gallery/GalleryModule";
import { Section } from "../components/Layout/Section";
import { SectionTitle } from "../components/Layout/SectionTitle";


const IndexPage = ({pageContext, data}) => {
    const [toggleTab, setToggleTab] = useState(false)
    const allImages = data.FilteredGallery.nodes.map((n, index) => {
     return n.gallery?.galleryImages?.map(g => {
            return {
                image: g.galleryImage?.childImageSharp,
                uid: g.galleryImage?.id,
                position: g.position
            }
        })
    }).flat()
  const content = data.portfolio.locales.find(l => l.iso === pageContext.lang).content
  const bgImage = data.portfolio.imageFile.publicURL
  console.log("BG IMAGE", bgImage)
  const allGalleriesWithTags = data.FilteredGallery.group.map(g => {
    return {
      uid: g.nodes[0].uid,
      image: g.nodes[0].imageFile?.childImageSharp,
      title: g.nodes[0].tags[0].locales.find(l => l.iso === pageContext.lang).title,
      value: g.nodes[0].locales.find(l => l.iso === pageContext.lang).value
    }
  })
  const projects =data.allPortfolio.nodes.map(n => {
    return {
      uid: n.uid,
      image:  n.imageFile?.childImageSharp,
      imageURL: n.imageFile?.publicURL,
      title:  n.locales.find(l => l.iso === pageContext.lang).title,
      value:  n.locales.find(l => l.iso === pageContext.lang).value
    }
  })
  return (
    <Layout insidePage breadCrumbLinks={pageContext.breadCrumb}>
        <TopBanner insidePage image={bgImage} title={pageContext.name} />
      <Container width="full-size">
        <div className={portfolioPageStyles.projectInfo}>
          <div className={portfolioPageStyles.content} dangerouslySetInnerHTML={{__html:content }} />
        </div>
        <div className={portfolioPageStyles.projectGallery}>
            <div className={portfolioPageStyles.tabs}>
                <button onClick={() => setToggleTab(false)} disabled={!toggleTab} className="gallery-btn">Gallery</button>
                <button onClick={() => setToggleTab(true)} disabled={toggleTab} className="project-areas">Areas</button>
            </div>
            <div className={portfolioPageStyles.galleryWrapper}>
                { !toggleTab && <div className={portfolioPageStyles.galleryAll}>
                    <GalleryModule animate={true} type="image" images={allImages} />
                </div>}
                { toggleTab && <div className={portfolioPageStyles.areas}>
                    <SimpleHomePageCarousel  options={
                      {
                        mobileHeight: "500px !important",
                        desktopHeight: "600px !important"
                      }
                    } height="576px" nodes={allGalleriesWithTags} />
                </div>}
            </div>
        </div>
      </Container>
      <Container width="full-size">
        <Section style={{marginTop: "75px"}}>
            <SectionTitle>
                Projects
            </SectionTitle>
          <SimpleHomePageCarousel  options={
            {
              mobileHeight: "500px !important",
              desktopHeight: "600px !important"
            }
          } width="600px" height="576px" nodes={projects} />
        </Section>
      </Container>
    </Layout>
  )
}
export const query = graphql`
    query ($name:String, $portfolioUid: String) {
        portfolio(uid: {eq: $portfolioUid}) {
            portfolioUrl
            position
            uid
            locales {
                iso
                content
                metaTitle
                metaDescription
            }
            imageFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
                publicURL
            }
            gallery {
                galleryImages {
                    galleryImage {
                        uid
                        id
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                    position
                }
                assets {
                    locales {
                        alt
                        iso
                    }
                }
                tags {
                    tagImage {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                    locales {
                        iso
                        title
                    }
                }
            }
            asset {
                locales {
                    alt
                    iso
                }
            }
            additionalData {
                key
                locales {
                    iso
                    value
                }
            }
            authorName
            authorUrl
            category {
                locales {
                    iso
                    title
                }
                uid
            }
        }
        allPortfolio {
            nodes {
                locales {
                    title
                    content
                    description
                    iso
                    metaDescription
                    metaKeywords
                    metaTitle
                    value
                }
                portfolioUrl
                position
                uid
                imageFile {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                    publicURL
                }
                gallery {
                    galleryImages {
                        galleryImage {
                            id
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                    }
                    assets {
                        locales {
                            alt
                            iso
                        }
                    }
                    tags {
                        tagImage {
                            childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                            }
                        }
                        locales {
                            iso
                            title
                        }
                    }
                }
                asset {
                    locales {
                        alt
                        iso
                    }
                }
                additionalData {
                    key
                    locales {
                        iso
                        value
                    }
                }
                authorName
                authorUrl
                category {
                    locales {
                        iso
                        title
                    }
                    uid
                }
            }
        }
        FilteredGallery:allGallery(
            filter: {additionalData: {elemMatch: {locales: {elemMatch: {value: {eq: $name}}}}}}
        ) {
           nodes {
               uid
               gallery {
                   galleryImages {
                       galleryImage {
                           id
                           childImageSharp {
                               gatsbyImageData(quality:70, layout: FULL_WIDTH)
                           }
                           publicURL
                       }
                   }
                   locales {
                       title
                   }
               }
           }
           group(field: tags___locales___title){
               nodes {
                   uid
                   locales {
                       iso
                       value
                       title
                   }
                   imageFile {
                       childImageSharp {
                           gatsbyImageData(layout: FULL_WIDTH)
                       }
                       publicURL
                   }
                   gallery {
                       galleryImages {
                           galleryImage {
                               id
                               childImageSharp {
                                   gatsbyImageData(quality:70, layout: FULL_WIDTH)
                               }
                               publicURL
                           }
                       }
                       locales {
                           title
                       }
                   }
                   tags {
                       tagImage {
                           childImageSharp {
                               gatsbyImageData(quality:70, layout: FULL_WIDTH)
                           }
                       }
                       locales {
                           iso
                           title
                       }
                   }
               }
           }
        }
    }
`

export const Head = ({data, pageContext}) => {
    const { metaTitle, metaDescription} = data.portfolio.locales.find(l => l.iso === pageContext.lang)
    return  <Seo title={metaTitle} description={metaDescription} />
}

export default IndexPage
